<template>
  <div>
    <BaseContainer class="pt-24 pb-16 relative z-20">
    <h2 class='text-cdlpink mt-12'>Are you a shelter or vet?</h2>
      <BaseHeadingAndText class='mt-12' heading="Our partners go crypto 🤘">
        We will not just give you a fish. We will show you how to fish. We help
        our partners go crypto so that long-term they can raise funds in a
        self-sustaining way from the good-hearted crypto community that would
        love to support them. The blockchain is perfect for vets, shelters and
        organizations since it makes donations public thus trackable and
        verifiable via the ledger.
      </BaseHeadingAndText>

      <BaseHeadingAndText class='mt-12' heading="What if I don't understand crypto? 😬">
        Are you new to crypto? Don't worry. We can send donations to your bank account. And once you learn more about the wonderful world of crypto and its advantages, we will be happy to help you to onboard.
      </BaseHeadingAndText>

      <BaseHeadingAndText class='mt-12' heading="Apply for funding">
      ~~~~~<br>
      (2 forms - shelters & vets)<br><br>

After the form is filled and sent this text will appear:
Thank you! We will contact you as soon as possible.<br>
~~~~~
      </BaseHeadingAndText>

      <BaseHeadingAndText class='mt-12 mb-12' heading="">
      ~~~~~logos of our partners~~~~~
      </BaseHeadingAndText>
    </BaseContainer>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/common/Footer"

export default {
  name: "Partners",
  components: {
    Footer,
  },
  data() {
    return {}
  },
}
</script>
